import React from "react";
import tw from "tailwind-styled-components";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { Text } from "@components";
import downArrow from "@assets/images/downArrow.png";

const Input = ({
  containerStyle,
  title,
  onShowPassword,
  visible,
  buttonStyle,
  clickOnDownArrow,
  errorText,
  inputStyle,
  ...props
}) => {
  return (
    <div className={`${containerStyle}`}>
      {title && (
        <Text
          style={{ fontSize: "16px" }}
          className="font-sans font-medium pb-2"
        >
          {title}
        </Text>
      )}
      {onShowPassword ? (
        <div className={inputWithIcons}>
          <div className="flex flex-1 flex-row w-full">
            <InputEye {...props} />
            <button onClick={onShowPassword} className={`${buttonStyle} `}>
              {visible ? (
                <RemoveRedEyeIcon sx={{ color: "#004996" }} />
              ) : (
                <VisibilityOffIcon sx={{ color: "#004996" }} />
              )}
            </button>
          </div>
        </div>
      ) : clickOnDownArrow ? (
        <div className={inputWithIcons}>
          <div className="flex flex-1 flex-row w-full">
            <InputEye {...props} />
            <button onClick={onShowPassword} className={buttonStyle}>
              <img alt="" src={downArrow} style={{ width: 18, height: 10 }} />
            </button>
          </div>
        </div>
      ) : (
        <CustomInput style={{ ...inputStyle }} {...props} />
      )}
      {errorText && (
        <div className="relative">
          {" "}
          <ErrorText>{`${errorText}`}</ErrorText>
        </div>
      )}
    </div>
  );
};

const CustomInput = tw.input`
w-full
p-2
pl-5
focus:outline-none 
border
border-inputBorder
rounded-md`;

const InputEye = tw.input`
w-[90%]
p-1
pl-4
focus:outline-none 
`;

const ErrorText = tw.p`
text-sm
text-red-500`;

const inputWithIcons = `
flex 
flex-row 
items-center 
justify-between`;

export default Input;
