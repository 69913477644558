import React from "react";
import { useTranslation } from "react-i18next";
import tw from "tailwind-styled-components";
import PlaceIcon from "@mui/icons-material/Place";
import SearchIcon from "@mui/icons-material/Search";
import { Button, DatePickers, Text } from "@components";
import "../../App.css";
import countryCode from "./../../countryCode/index.json";

const SearchInput = ({
  isCompact,
  selectedDate,
  setSearch,
  value,
  setShow,
  goToSearch,
  changeSelectedDate,
}) => {
  const { t } = useTranslation("common");

  const navigateToSearch = (e) => {
    e?.stopPropagation();
    goToSearch("text");
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      navigateToSearch();
    }
  };

  return (
    <div className={isCompact ? Container : Container2}>
      <ContainerInner>
        <div className={Date}>
          <DatePickers
            newDate={(e) => changeSelectedDate(e)}
            value={selectedDate}
          />
        </div>
        <div
          className="flex w-full md:w-9/12 h-full items-center"
          onClick={setShow}
        >
          <div className="w-[40px]">
            {countryCode[value?.toLowerCase()] && (
              <img
                src={`https://flagcdn.com/w40/${
                  countryCode[value?.toLowerCase()]
                }.png`}
                srcSet={`https://flagcdn.com/w80/${
                  countryCode[value?.toLowerCase()]
                }.png 2x`}
                className="w-[40px] h-[20px] bg-white"
              />
            )}
          </div>
          <PlaceIcon className="text-primary  self-center" />
          <input
            value={value}
            className={Input}
            placeholder={t("header.search_Placeholder")}
            onChange={setSearch}
            onKeyDown={handleKeyPress}
            autoComplete="off"
            role="presentation"
          />
          <Button
            variant="primary"
            className={`${SearchBox} flex xl1:hidden p-3`}
            onClick={navigateToSearch}
          >
            <SearchIcon sx={{ color: "white" }} fontSize="small" />
          </Button>
          <Button
            variant="primary"
            className={`${SearchBox} hidden xl1:flex hover:opacity-80`}
            onClick={navigateToSearch}
          >
            <Text className="invisible lg:visible font-sans text-[18px] font-semibold text-btnTextColor">
              {t("header.search_Btn")}
            </Text>
          </Button>
        </div>
      </ContainerInner>
    </div>
  );
};

const Container = `
w-full 
flex 
items-end 
justify-center
`;

const Container2 = `
w-full 
flex 
items-end 
justify-center 
`;

const ContainerInner = tw.div`
mx-4
md2:mx-0 
w-[90%]
md:w-[550px]
lg:w-[730px]
xl1:w-[950px]
xl3:w-[1100px] 
h-[54px] 
bg-white
rounded-lg 
shadow-[0_0px_20px_0px_#00000054]
flex 
flex-row 
items-center 
justify-between`;

const Date = `
xl:w-[20%]
lg:w-[25%] 
md:w-[28%]
md:w-[35%]
w-[50%]
border-e-[0.11px] 
h-fit 
flex 
flex-row 
items-center 
justify-start 
truncate
mr-2
`;

const Input = `
w-full 
self-center 
h-full 
text-black
font-sans
text-[16px] 
px-2 
rounded-lg 
focus:outline-none 
focus:border-[gray] 
placeholder:text-gray-400`;

const SearchBox = `
webkitHighlight
h-[100%]
w-[26%]
bg-primary 
flex 
items-center 
rounded-r-lg
justify-center`;

export default SearchInput;
