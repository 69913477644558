import { createSlice } from "@reduxjs/toolkit";
import {
  golfclixAuthenticateUser,
  resetPassword,
  registerGolfclixUser,
  getClubList,
  updateClubList,
  updateProfile,
  getUserDetail,
} from "./actions";
import { Storage } from "@utils";

const initialState = {
  userData: null,
  userDetail: null,
  userToken: null,
  resetToken: null,
  clubList: [],
};

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUserDetails: (state, action) => {
      state.userData = action?.payload;
    },
    updateGolfclixUserDetail: (state, action) => {
      state.userDetail = action?.payload;
    },
    updateGolfclixUserToken: (state, action) => {
      state.userToken = action?.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(golfclixAuthenticateUser.fulfilled, (state, action) => {
      state.userDetail = action?.payload?.data?.data;
      state.userToken = action?.payload?.data?.data?.token;
      action?.payload?.status === 200 &&
        action?.payload?.data?.data?.token &&
        Storage.save(
          Storage.USER_DETAIL,
          JSON.stringify(action?.payload?.data?.data)
        ) &&
        Storage.save(
          "W2G_UserToken",
          JSON.stringify(action?.payload?.data?.data?.token)
        );
    });
    builder.addCase(updateProfile.fulfilled, (state, action) => {
      if (action?.payload?.status === 200 && action?.payload?.data?.data) {
        Storage.save(
          Storage.USER_DETAIL,
          JSON.stringify(action?.payload?.data?.data)
        );
        state.userDetail = action?.payload?.data?.data;
      }
    });
    builder.addCase(getUserDetail.fulfilled, (state, action) => {
      if (action?.payload?.status === 200 && action?.payload?.data?.data) {
        Storage.save(
          Storage.USER_DETAIL,
          JSON.stringify(action?.payload?.data?.data)
        );
        state.userDetail = action?.payload?.data?.data;
      }
    });
    builder.addCase(getClubList.fulfilled, (state, action) => {
      if (action?.payload?.status === 200 && action?.payload?.data?.data) {
        state.clubList = action?.payload?.data?.data?.result;
      }
    });
    builder.addCase(updateClubList.fulfilled, (state, action) => {
      if (action?.payload?.status === 200 && action?.payload?.data?.data) {
        state.clubList = state.clubList.concat(
          action?.payload?.data?.data?.result
        );
      }
    });
    builder.addCase(registerGolfclixUser.fulfilled, (state, action) => {
      if (
        action?.payload?.status === 200 &&
        action?.payload?.data?.data?.token
      ) {
        state.userToken = action?.payload?.data?.data?.token;
        Storage.save(
          "W2G_UserToken",
          JSON.stringify(action?.payload?.data?.data?.token)
        );
      }
    });

    builder.addCase(resetPassword.fulfilled, (state, action) => {
      state.resetToken = action?.meta?.arg?.token;
      Storage.save(Storage.RESET_TOKEN, action?.meta?.arg?.token);
    });
  },
});

export const {
  setUserDetails,
  updateGolfclixUserDetail,
  updateGolfclixUserToken,
} = slice.actions;

export default slice.reducer;
