import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useGeolocated } from "react-geolocated";
import { Footer } from "@components";
import { Storage } from "@utils";
import ErrorPage from "@pages/errorPage";
import {
  setLanguage,
  setUserCountry,
  setCoordinates,
  upsertRecentSearches,
  upsertRecentViewedNews,
  setDefaultCountryCoordinates,
} from "@redux/user/slice";
import {
  getTerms,
  getSetting,
  getAbout_US,
  getPrivacyData,
  getHomePageSetting,
  getCancellationPolicy,
} from "@redux/settings/actions";
import { change_Language } from "@redux/settings/slice";
import { getCountrysList } from "@redux/teetime/actions";
import {
  updateGolfclixUserDetail,
  updateGolfclixUserToken,
} from "@redux/authentication/slice";
import { getMenus, getHeaderData } from "@redux/header/actions";
import routes from "./routes";

const App = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { i18n } = useTranslation("common");
  const { setting } = useSelector((store) => store.settingsReducer);
  const defaultCountry =
    setting?.data?.attributes?.country?.data?.attributes?.name;
  const { current_Language, homePageSetting } = useSelector(
    (store) => store.settingsReducer
  );
  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: false,
      },
      userDecisionTimeout: 5000,
    });
  const { userCountry } = useSelector((store) => store.userReducer);
  const { userDetail } = useSelector((store) => store.authReducer);
  const { menus, logo } = useSelector((state) => state.headerReducer);
  const { countryList } = useSelector((store) => store.teetimeReducer);

  const [isServerLoading, setIsServerLoading] = useState(true);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const token = query.get("token");
    if (token) {
      navigate(`/reset-password?token=${token}`);
    }
  }, [location]);

  useEffect(() => {
    if (!countryList?.length) {
      dispatch(getCountrysList());
    }
    if (!menus) {
      dispatch(getMenus());
    }
    if (!logo) {
      dispatch(getHeaderData());
    }
    if (!setting) {
      dispatch(getSetting(current_Language));
    }

    setTimeout(() => {
      setIsServerLoading(false);
    }, 2000);

    checkUserAuthentication();
    Storage.remove(Storage.PLAYERS_DETAIL);
    const initialize = async () => {
      let recentSearches = await Storage.get(Storage.RECENT_SEARCHES);
      let recentViewedNews = await Storage.get(Storage.RECENT_VIEWED_NEWS);
      if (recentSearches) {
        let parsedRecentSearches = JSON.parse(recentSearches);
        dispatch(upsertRecentSearches(parsedRecentSearches));
      }
      if (recentViewedNews) {
        let parsedRecentViewedNews = JSON.parse(recentViewedNews);
        dispatch(upsertRecentViewedNews(parsedRecentViewedNews));
      }
    };
    initialize();
  }, []);

  useEffect(() => {
    fetch("https://ipapi.co/json/")
      .then((response) => response.json())
      .then((data) => {
        if (data && data?.country_name) {
          dispatch(
            setUserCountry({
              country: data?.country_name,
            })
          );
        }
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    if (userDetail && userDetail?.language) {
      if (userDetail?.language === "en") {
        handleLanguageChange({
          code: "GB",
          language: "en",
        });
      } else if (userDetail?.language === "de") {
        handleLanguageChange({
          code: "DE",
          language: "de",
        });
      }
    } else {
      if (
        userCountry?.country === "Germany" ||
        userCountry?.country === "Austria" ||
        userCountry?.country === "Belgium" ||
        userCountry?.country === "Luxembourg" ||
        userCountry?.country === "Switzerland" ||
        userCountry?.country === "Liechtenstein"
      ) {
        handleLanguageChange({
          code: "DE",
          language: "de",
        });
      } else {
        handleLanguageChange({
          code: "GB",
          language: "en",
        });
      }
    }
  }, [userDetail, userCountry]);

  const handleLanguageChange = (props) => {
    dispatch(setLanguage(props));
    i18n.changeLanguage(props.language);
    Storage.save(Storage.LANGUAGE, props.language);
    dispatch(change_Language(props.language));
  };

  useEffect(() => {
    if (isGeolocationAvailable && isGeolocationEnabled && coords) {
      if (coords.latitude && coords.longitude) {
        const fetchCountryURL = `https://api.opencagedata.com/geocode/v1/json?q=${
          coords.latitude
        }+${coords.longitude}&key=${"1c9a9c1c266d47acb66de1b1b354c6e5"}`;

        axios.get(fetchCountryURL).then((response) => {
          if (response?.data?.results) {
            dispatch(
              setCoordinates({
                lat: coords.latitude,
                lng: coords.longitude,
                country: response?.data?.results[0]?.components?.country,
              })
            );
          } else {
            dispatch(
              setCoordinates({
                lat: coords.latitude,
                lng: coords.longitude,
                country: "",
              })
            );
          }
        });
      }
    }
  }, [isGeolocationAvailable, isGeolocationEnabled, coords]);

  useEffect(() => {
    checkLanguage();
  }, [current_Language]);

  async function checkLanguage() {
    try {
      let selectLanguage = await Storage.get(Storage.LANGUAGE);
      let new_Language = selectLanguage.replace(/"/g, "");
      i18n.changeLanguage(new_Language);
      if (current_Language === new_Language) {
        dispatch(getTerms(`terms-cond?locale=${new_Language}`));
        dispatch(getPrivacyData(`privacy-policy?locale=${new_Language}`));
        dispatch(
          getCancellationPolicy(`cancellation-policy?locale=${new_Language}`)
        );
        dispatch(getAbout_US(`about-us?locale=${new_Language}`));
        dispatch(getHomePageSetting(new_Language));
      } else {
        dispatch(change_Language(new_Language));
      }
    } catch (error) {
      dispatch(getTerms(`terms-cond?locale=${current_Language}`));
      dispatch(getPrivacyData(`privacy-policy?locale=${current_Language}`));
      dispatch(
        getCancellationPolicy(`cancellation-policy?locale=${current_Language}`)
      );
      dispatch(getAbout_US(`about-us?locale=${current_Language}`));
      dispatch(getHomePageSetting(current_Language));
    }
  }

  useEffect(() => {
    if (defaultCountry) {
      const fetchGeolocation = async () => {
        try {
          const response = await fetch(
            `https://api.opencagedata.com/geocode/v1/json?q=${defaultCountry}&key=1c9a9c1c266d47acb66de1b1b354c6e5`
          );
          const data = await response.json();
          if (data?.results?.length) {
            dispatch(
              setDefaultCountryCoordinates({
                lat: data?.results[0]?.geometry?.lat,
                lng: data?.results[0]?.geometry?.lng,
                country: defaultCountry,
              })
            );
          }
        } catch (error) {}
      };

      fetchGeolocation();
    }
  }, [defaultCountry]);

  const checkUserAuthentication = async () => {
    let userDetails = await Storage.get(Storage.USER_DETAIL);
    let userToken = await Storage.get("W2G_UserToken");
    if (userDetails && userToken) {
      let user = JSON.parse(userDetails);
      let token = JSON.parse(userToken);
      dispatch(updateGolfclixUserDetail(user));
      dispatch(updateGolfclixUserToken(token));
    }
  };

  const isUnderMaintenance =
    !homePageSetting?.length &&
    !menus?.data?.length &&
    !logo?.data?.id &&
    !setting?.data?.id &&
    !countryList?.length;

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {isUnderMaintenance && !isServerLoading ? (
        <div
          style={{
            width: "100%",
            height: "200px",
            display: "flex",
            margin: "auto",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-around",
            backgroundColor: "white",
          }}
        >
          <div>
            <h1
              style={{
                marginTop: "50px",
                color: "gray",
                fontWeight: "bold",
                fontSize: "20px",
              }}
            >
              Welcome To GolfClix{" "}
            </h1>
          </div>
          <div
            style={{
              marginTop: "50px",
              color: "#019442",
              fontWeight: "bold",
              fontSize: "30px",
            }}
          >
            <h1 className="animate-pulse ">Server Under Maintenance </h1>
          </div>
        </div>
      ) : isServerLoading ? (
        <div className="w-full h-[300px] flex justify-center items-center">
          <svg className="w-16 h-16 bg-transparent border-4 animate-spin rounded-full border-t-[white] border-[#019442]" />
        </div>
      ) : (
        <div style={{ width: "100%" }}>
          <Routes>
            {routes.map((item, index) => (
              <Route
                key={"" + index}
                path={item.path}
                exact={item.exact}
                name={item.name}
                element={item.element}
              />
            ))}
            <Route path="*" element={<ErrorPage />} />
          </Routes>
          <Footer />
        </div>
      )}
    </div>
  );
};

export default App;
