import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import tw from "tailwind-styled-components";
import { toast } from "react-toastify";
import autoAnimate from "@formkit/auto-animate";
import FlagIcon from "@mui/icons-material/Flag";
import PlaceIcon from "@mui/icons-material/Place";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import SearchIcon from "@mui/icons-material/Search";
import { Text, Button } from "@components";
import { Storage, updateRecentData, imageUrlBuilder } from "@utils";
import { updateCustomDate } from "@redux/header/slice";
import { upsertRecentSearches } from "@redux/user/slice";
import { clubSearchApiList } from "@redux/teetime/actions";
import countryCode from "./../../countryCode/index.json";
import "../../App.css";

const SearchInput = ({ value, setShow, setSearch, isCompact, goToSearch }) => {
  const { t } = useTranslation("common");

  const navigateToSearch = (e) => {
    e?.stopPropagation();
    goToSearch("text");
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      navigateToSearch();
    }
  };

  return (
    <div className={isCompact ? Container1 : Container2}>
      <ContainerInner>
        <div className="flex w-full h-full items-center" onClick={setShow}>
          <input
            value={value}
            className={Input}
            placeholder={t("header.search_Placeholder")}
            onChange={setSearch}
            onKeyDown={handleKeyPress}
            autoComplete="off"
            role="presentation"
          />

          <SearchIcon
            sx={{ color: "gray" }}
            fontSize="small"
            className="absolute right-2 md2:right-5 "
          />
        </div>
      </ContainerInner>
    </div>
  );
};

const SearchBarHeader = ({
  isCompact = true,
  searchInputProps,
  searchMenuProps,
  showSuggestionBox,
  hideSuggestionBox,
  show,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const timeoutRef = useRef();
  const courseRef = useRef();
  const locationRef = useRef();
  const { t } = useTranslation("common");
  const { recentSearches } = useSelector((store) => store.userReducer);
  const { logo, customDate } = useSelector((state) => state.headerReducer);
  const { exploreGame, clubSearchList } = useSelector(
    (store) => store.teetimeReducer
  );
  let homeBgImg = "";
  let courseBgImg = "";

  setTimeout(() => {
    homeBgImg =
      logo?.data && logo?.data?.attributes?.bgImage?.data[0]?.attributes?.url;
    courseBgImg =
      logo?.data && logo?.data?.attributes?.bgImage?.data[1]?.attributes?.url;
  }, [2000]);

  const [state, setState] = useState({
    search: "",
    show: false,
    courseList: true,
    locationList: true,
    announcementText: "",
  });

  const changeDate = (e) => dispatch(updateCustomDate({ date: e }));

  const setSearchData = (e) => {
    let payload = `${e.target.value}`;
    setState((prev) => ({ ...prev, search: payload }));
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      dispatch(clubSearchApiList(payload));
    }, 1000);
  };

  const updateRecentSearches = async (e) => {
    let recentSearchesData = [...recentSearches];
    updateRecentData(recentSearchesData, e);
    dispatch(upsertRecentSearches(recentSearchesData));
    Storage.save(Storage.RECENT_SEARCHES, JSON.stringify(recentSearchesData));
  };

  const onSearch = (e) => {
    let query = `?date=${moment(customDate).toISOString()}`;
    if (e === "text") {
      if (state.search.length) {
        if (clubSearchList?.location?.length) {
          if (
            clubSearchList?.location?.every((item) =>
              item?.country?.toLowerCase()?.includes(state.search.toLowerCase())
            )
          ) {
            query = query + "&country=" + clubSearchList?.location[0]?.country;
          } else {
            // if (clubSearchList?.location[0]?.place?.toLowerCase().includes(state.search.toLowerCase()) || clubSearchList?.location[0]?.country?.toLowerCase().includes(state.search.toLowerCase())) {
            query =
              query +
              "&place=" +
              clubSearchList?.location[0]?.place +
              "&country=" +
              clubSearchList?.location[0]?.country;
          }
        } else if (clubSearchList?.club?.length) {
          query = query + "&name=" + clubSearchList?.club[0]?.club;
        }
      }
      window.location.search !== query &&
        navigate({
          pathname: `/courses`,
          search: query,
        });
    } else {
      if (!e) {
        toast.error(t("header.retry_Search"));
        return;
      }
      if (e?.club) {
        query = query + "&name=" + e.club;
      }
      if (e?.place) {
        query = query + "&place=" + e.place;
      }
      if (e?.country) {
        query = query + "&country=" + e.country;
      }
      if (e?.slug) {
        query = query + "&slug=" + e.slug;
      } else if (e) {
        updateRecentSearches(e);
      }

      if (e?.club) {
        navigate(`/course/${e?.club.toString()}`);
      } else {
        navigate({
          pathname: `/courses`,
          search: query,
        });
      }
    }
  };

  useEffect(() => {
    if (courseRef.current) {
      autoAnimate(courseRef.current);
    }
  }, [courseRef]);

  const showMoreCourse = (e) => {
    e.stopPropagation();
    setState((prev) => ({
      ...prev,
      courseList: !state.courseList,
    }));
  };

  useEffect(() => {
    if (locationRef.current) {
      autoAnimate(locationRef.current);
    }
  }, [locationRef]);

  const showMoreLocation = (e) => {
    e.stopPropagation();
    setState((prev) => ({
      ...prev,
      courseList: false,
      locationList: !state.locationList,
    }));
  };

  const SearchMenu = ({
    suggestionSearch,
    recentSearch,
    clubSearchList,
    search,
    onSearch,
  }) => {
    const { club, location } = clubSearchList;

    return (
      <div className={SearchContainer}>
        {(club.length || location.length) && search.length ? (
          <>
            {club.length ? (
              <div className=" w-full h-auto" ref={courseRef}>
                <div
                  onClick={showMoreCourse}
                  className={`w-full flex justify-between items-center cursor-pointer shadow-[0_0px_20px_#3c7fed90] pl-2 ${
                    state.courseList ? "rounded-t-lg" : "rounded-lg"
                  }`}
                >
                  <p className="text-[20px] text-[black] font-sans font-semibold">
                    {t("header.courses")}
                  </p>
                  <div
                    className={`w-fit h-fit ${
                      state.courseList
                        ? "duration-500 transform rotate-90"
                        : "duration-300 transform rotate-0"
                    }`}
                  >
                    <ArrowRightIcon
                      fontSize="large"
                      sx={{ color: "#000000" }}
                    />
                  </div>
                </div>
                {state.courseList && (
                  <div
                    className={`flex flex-col bg-[#ffffffad] h-auto max-h-[220px] overflow-x-hidden overflow-y-scroll custom-scroll ${
                      state.courseList ? " rounded-b-lg" : "rounded-lg"
                    }`}
                  >
                    {club?.map((ele, ind) => {
                      return (
                        <Button
                          key={ind}
                          onClick={() => onSearch(ele)}
                          variant="primary"
                          className="mt-1 flex items-center"
                        >
                          <span className="py-2 flex w-[80%] pl-1">
                            <FlagIcon className="text-primary self-center mr-1" />
                            <Text className={SearchSuggestionText}>
                              {ele?.club}
                            </Text>
                          </span>
                          <span className="py-1 mr-1 my-1  px-3 text-white flex bg-primary rounded-xl min-w-[100px] hover:opacity-80">
                            {t("destination.book_Now")}
                          </span>
                        </Button>
                      );
                    })}
                  </div>
                )}
                <hr className="border-primary my-2" />
              </div>
            ) : null}
            {location.length ? (
              <div className=" w-full h-auto" ref={locationRef}>
                <div
                  onClick={showMoreLocation}
                  className={`w-full flex justify-between items-center cursor-pointer shadow-[0_0px_20px_#3c7fed90]  pl-2 ${
                    state.locationList ? "rounded-t-lg" : "rounded-lg"
                  }`}
                >
                  <p className="text-[18px] font-sans text-black font-semibold">
                    {t("header.locations")}
                  </p>
                  <div
                    className={`w-fit h-fit ${
                      state.locationList
                        ? "duration-500 transform rotate-90"
                        : "duration-300 transform rotate-0"
                    }`}
                  >
                    <ArrowRightIcon
                      fontSize="large"
                      sx={{ color: "#000000" }}
                    />
                  </div>
                </div>
                {state.locationList && (
                  <div
                    className={`flex flex-col bg-[#ffffffad] h-auto max-h-[200px] overflow-x-hidden overflow-y-scroll custom-scroll ${
                      state.locationList ? " rounded-b-lg" : "rounded-lg"
                    }`}
                  >
                    {location?.every(
                      (it) => it.country === location[0].country
                    ) && (
                      <Button
                        key={-1}
                        onClick={() =>
                          onSearch({ country: location[0].country })
                        }
                        variant="primary"
                        className="mt-1 pl-1"
                      >
                        <div className="py-2 flex gap-x-2">
                          <PlaceIcon className="text-primary  self-center" />
                          {countryCode[location[0]?.country?.toLowerCase()] && (
                            <img
                              style={{
                                width: "1.3em",
                                height: "0.8em",
                                marginTop: "0.3em",
                                marginRight: "0.2em",
                              }}
                              src={`https://flagcdn.com/w40/${
                                countryCode[location[0]?.country?.toLowerCase()]
                              }.png`}
                              srcSet={`https://flagcdn.com/w80/${
                                countryCode[location[0]?.country?.toLowerCase()]
                              }.png 2x`}
                              alt="flag"
                            />
                          )}
                          <Text className={SearchSuggestionText}>
                            {`${location[0]?.country}, `}
                          </Text>
                          <span
                            className="font-sans font-thin italic text-black hover:none ml-1"
                            style={{ fontWeight: "lighter" }}
                          >{`${location?.length} golf club`}</span>
                        </div>
                      </Button>
                    )}
                    {location?.map((ele, ind) => {
                      return (
                        <Button
                          key={ind}
                          onClick={() => onSearch(ele)}
                          variant="primary"
                          className="mt-1 pl-1"
                        >
                          <div className="py-2 flex gap-x-2">
                            <PlaceIcon className="text-primary  self-center" />
                            {countryCode[ele?.country?.toLowerCase()] && (
                              <img
                                style={{
                                  width: "1.3em",
                                  height: "0.8em",
                                  marginTop: "0.3em",
                                  marginRight: "0.2em",
                                }}
                                src={`https://flagcdn.com/w40/${
                                  countryCode[ele?.country?.toLowerCase()]
                                }.png`}
                                srcSet={`https://flagcdn.com/w80/${
                                  countryCode[ele?.country?.toLowerCase()]
                                }.png 2x`}
                                alt="flag"
                              />
                            )}
                            <Text className={SearchSuggestionText}>
                              {`${ele?.place}, ${ele?.country}`}
                            </Text>
                          </div>
                        </Button>
                      );
                    })}
                  </div>
                )}
              </div>
            ) : null}
          </>
        ) : (
          <>
            {search?.length ? (
              <>
                <p className="text-[18px] font-sans text-black font-semibold">
                  {t("header.no_Results")}
                </p>
                <hr className="border-[#dddddddd] pb-5" />
              </>
            ) : recentSearch?.length > 0 ? (
              <>
                <p className="text-[18px] text-[black] font-semibold">
                  {t("header.recent_Search")}
                </p>
                <div className="flex flex-col">
                  {recentSearch?.map((ele, ind) => {
                    return (
                      <Button
                        key={ind}
                        onClick={() => onSearch(ele)}
                        variant="primary"
                      >
                        <p className="py-2 flex">
                          {ele?.type === "club" ? (
                            <FlagIcon className="text-primary mr-1 self-center" />
                          ) : (
                            <PlaceIcon className="text-primary mr-1 self-center" />
                          )}
                          <Text className="text-[18px] text-[black] font-semibold hover:underline ">
                            {ele?.type === "club"
                              ? ele?.club
                              : ele?.place
                              ? `${ele?.place}, ${ele?.country}`
                              : ele?.country}
                          </Text>
                        </p>
                      </Button>
                    );
                  })}
                </div>
                <hr className="border-[#dddddddd] pb-5" />
              </>
            ) : null}
            {suggestionSearch?.length ? (
              <>
                <p className="text-[18px] font-sans text-black font-semibold">
                  {t("header.quick_Search")}
                </p>
                <div className="flex flex-row flex-wrap gap-x-2">
                  {suggestionSearch?.map((val, ind) => {
                    const url = imageUrlBuilder(
                      val?.attributes?.image?.data &&
                        val?.attributes?.image?.data?.attributes.url
                    );
                    return (
                      <div className="flex flex-row p-2" key={ind}>
                        <Button
                          onClick={() => {
                            onSearch({ slug: val?.attributes?.slug });
                          }}
                          variant="primary"
                        >
                          <Li className="hover:bg-[#f0efef]">
                            <img src={url} height={30} width={30} alt="" />
                            <Text className="font-sans text-[14px] text-[black] pl-2">
                              {val?.attributes?.title}
                            </Text>
                          </Li>
                        </Button>
                      </div>
                    );
                  })}
                </div>
              </>
            ) : null}
          </>
        )}
      </div>
    );
  };

  return (
    <Container onClick={hideSuggestionBox} className="absolute w-full ">
      <div className="w-full h-auto relative">
        <div className="absolute inset-0">
          <SearchInput
            isCompact={isCompact}
            value={state.search}
            selectedDate={customDate}
            setShow={showSuggestionBox}
            changeSelectedDate={changeDate}
            setSearch={setSearchData}
            goToSearch={onSearch}
            {...searchInputProps}
          />
          <div
            className={
              show
                ? "absolute z-[998] left-0 xl:left-[-50px] xl1:left-0 right-0 invisible md2:visible w-0 md2:w-full xl:w-[350px] xl1:w-full "
                : "invisible"
            }
          >
            <SearchMenu
              suggestionSearch={exploreGame?.data}
              recentSearch={recentSearches}
              clubSearchList={clubSearchList}
              search={state.search}
              onSearch={onSearch}
              {...searchMenuProps}
            />
          </div>
        </div>
      </div>
    </Container>
  );
};

const Container = tw.div`
bg-white
`;

const SearchSuggestionText = `
text-black
font-sans
font-semibold
hover:underline
underline-offset-2
`;

const Li = tw.div`
flex-1
flex 
p-2
justify-center
items-center
bg-white
flex
border-[#dddddddd] 
rounded-md 
self-center
`;

const SearchContainer = `
h-[300px] 
flex 
flex-col 
w-full
min:w-[250px]
border-[gray] 
bg-[#ffffff] 
rounded-lg 
shadow-[0_0px_20px_#3c7fed90]
mt-5 
px-5
pt-2 
pb-5
text-white 
m-auto 
overflow-y-scroll 
overflow-x-hidden 
custom-scroll 
transparent`;

const Container1 = `
w-full 
flex 
items-end 
justify-center 
`;

const Container2 = `
w-full 
flex 
items-end 
justify-center 
`;

const ContainerInner = tw.div`
mx-2
md2:mx-4 
w-full
h-[30px]
xl:h-[40px]
bg-white
rounded-full
flex 
flex-row 
items-center 
justify-between`;

const Input = `
w-full 
self-center 
h-full 
text-black
font-sans
text-[16px] 
md2:px-2
mr-5
rounded-lg 
focus:outline-none 
focus:border-[gray] 
placeholder:text-gray-400`;

export default SearchBarHeader;
