import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import moment from "moment";
import tw from "tailwind-styled-components";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Tooltip, styled, tooltipClasses } from "@mui/material";
import { Input, Button, Text } from "@components";
import { isEmail } from "@utils";
import { newsLetter } from "@redux/news/actions";
import theme from "../../theme.js";

const FooterText = (props) => {
  return (
    <div className={footerText}>
      <ArrowForwardIosIcon
        sx={{ color: theme.colors.primary, height: "12px", width: "12px" }}
      />
      <Text className={text}>{props.children}</Text>
    </div>
  );
};

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#ffff",
    color: "rgba(0, 0, 0, 0.6)",
    maxWidth: 220,
    fontSize: 14,
    border: "0.5px solid rgb(0,0,0)",
    borderRadius: 10,
  },
  "& .MuiTooltip-arrow": {
    color: "#ffff",
  },
}));

const styles = {
  footerContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    margin: "auto",
    width: "full",
    height: "auto",
    backgroundColor: "white",
  },
  mainView: {
    width: "100%",
    maxWidth: "full",
    height: "auto",
  },
};

const Footer = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation("common");
  const data = useSelector((state) => state?.headerReducer);
  const { userDetail } = useSelector((store) => store.authReducer);

  const [state, setState] = useState({
    email: "",
    postalCode: "",
    loading: false,
    email_Error: false,
    validEmail_Error: false,
    postalCode_Error: false,
  });

  useEffect(() => {
    if (userDetail?.email) {
      setState((prev) => ({ ...prev, email: userDetail.email }));
    }
  }, [userDetail]);

  const FooterNav = data?.menus?.data?.filter(
    (val, ind) => val?.attributes?.slug !== "header-nav"
  );

  const onChangeEmail = (e) => {
    setState((prev) => ({
      ...prev,
      email: e.target.value,
      email_Error: false,
      validEmail_Error: false,
    }));
  };

  const onChangeCode = (e) => {
    setState((prev) => ({
      ...prev,
      postalCode: e.target.value,
      postalCode_Error: false,
    }));
  };

  const subscribe = () => {
    if (state.email && state.postalCode) {
      if (isEmail(state.email)) {
        setState((prev) => ({ ...prev, loading: true }));
        dispatch(
          newsLetter({
            data: {
              email: state.email,
              postalCode: state.postalCode,
            },
          })
        ).then((res) => {
          setState((prev) => ({ ...prev, loading: false }));
          if (
            res?.payload?.data &&
            (res?.payload?.status === 200 || res?.payload?.status === 201)
          ) {
            setState((prev) => ({ ...prev, email: "", postalCode: "" }));
            toast.success("Succesfully subscribed to newsletter!");
          } else {
            setState((prev) => ({ ...prev, email: "", postalCode: "" }));
            toast.error(
              res?.payload?.response?.data?.error?.message ||
                res?.payload?.response?.data?.message ||
                "A generic error occurred on this sbuscribe"
            );
          }
        });
      } else {
        setState((prev) => ({
          ...prev,
          email_Error: false,
          validEmail_Error: true,
        }));
      }
    } else {
      setState((prev) => ({
        ...prev,
        email_Error: !state.email,
        postalCode_Error: !state.password,
      }));
    }
  };

  return (
    <div style={styles.footerContainer}>
      <div className={FooterContainer}>
        {FooterNav?.map((item, index) => {
          const FooterNav = item?.attributes?.items?.data;
          return (
            <FooterDiv key={index}>
              <FooterTitle>{item?.attributes?.title}</FooterTitle>
              {FooterNav.map((items, key) => {
                return (
                  <NavLink
                    key={items?.attributes?.title}
                    to={items?.attributes?.url}
                    target={item?.attributes?.target}
                  >
                    <FooterText>{items?.attributes?.title}</FooterText>
                  </NavLink>
                );
              })}
            </FooterDiv>
          );
        })}

        {FooterNav && (
          <FooterDiv className="max-w-[330px]">
            <FooterTitle>{t("footer.keepIn")}</FooterTitle>
            <Text className={text}>
              {t("footer.subscribe_message")}
              <div className="text-[16px]">
                <HtmlTooltip
                  arrow
                  placement="bottom"
                  className="font-sans"
                  title={<div className="p-2">{t("footer.hover_message")}</div>}
                >
                  <InfoOutlinedIcon
                    fontSize="inherit"
                    className="text-primary"
                  />
                </HtmlTooltip>
              </div>
            </Text>

            <div className="w-full">
              <Input
                className={InputStyle}
                placeholder={t("footer.email")}
                autoComplete="off"
                role="presentation"
                value={state.email}
                type={"email"}
                required
                maxlength="35"
                onChange={(e) => onChangeEmail(e)}
                errorText={
                  state.email_Error
                    ? t("auth.enter_email")
                    : state.validEmail_Error
                    ? t("auth.enter_valid_email")
                    : ""
                }
              />
              <div className="mt-4">
                <Input
                  onChange={(e) => onChangeCode(e)}
                  value={state.postalCode}
                  className={InputStyle}
                  placeholder={t("footer.postal_code")}
                  autoComplete="off"
                  role="presentation"
                  type={"text"}
                  required
                  maxlength="30"
                  errorText={
                    state.postalCode_Error ? t("footer.enter_PostalCode") : ""
                  }
                />
              </div>
            </div>
            <Button
              style={{ width: "183px", height: "50px" }}
              variant="secondary"
              onClick={() => subscribe()}
              text={
                state.loading ? (
                  <div className="flex justify-center w-full">
                    <div className="w-6 h-6 border-4 border-btnTextColor/10 border-t-btnTextColor border-solid rounded-full animate-spin" />
                  </div>
                ) : (
                  <Text className="font-sans text-[18px] font-semibold ">
                    {t("footer.subscribe")}
                  </Text>
                )
              }
            />
          </FooterDiv>
        )}
      </div>
      <div className={Copyright}>
        <Text className="text-btnTextColor text-[13px] opacity-70">
          {t("footer.copyright") +
            " " +
            moment().format("YYYY") +
            " " +
            t("footer.rights")}
        </Text>
      </div>
    </div>
  );
};

const FooterDiv = tw.div`
w-fit 
h-auto 
flex 
flex-col 
items-start 
justify-start 
gap-y-4
`;
const FooterTitle = tw.span`
text-[1.125rem] 
font-semibold 
text-btnTextColor
text-md
`;
const text = `
text-[13px]
text-btnTextColor
opacity-70
`;

const FooterContainer = `
w-full 
h-auto 
bg-textColor 
flex 
flex-wrap 
justify-between 
items-start 
gap-x-6 
gap-y-20 
py-20 
xl:px-40 
lg-px-20 
md:px-8 
px-6`;

const InputStyle = `
w-full 
bg-btnTextColor 
text-[gray] 
rounded-lg 
py-[11px] 
mb-2
px-4 
leading-tight 
focus:outline-none 
focus:border-[gray] 
focus:bg-[#d9d9d9]
placeholder:text-[gray]
`;

const Copyright = `
w-full 
h-auto 
py-6 
flex 
items-center 
justify-center 
border-t-[0.1px] 
border-t-[#a9a9a9] 
bg-textColor`;

const footerText = `
flex 
flex-row 
items-center  
gap-[3px] 
no-underline 
hover:underline 
decoration-btnTextColor 
underline-offset-4 
hover:cursor-pointer`;

export default Footer;
