import { createSlice } from "@reduxjs/toolkit";
import {
  getHomePageSetting,
  raiseQuery,
  getSetting,
  getPrivacyData,
  getCancellationPolicy,
  getAbout_US,
  getTerms,
  getGolfclixCountry,
} from "./actions";
import _ from "lodash";

const initialState = {
  homePageSetting: [],
  setting: null,
  cancellPolicy: null,
  about_Us: null,
  termsData: null,
  privacyData: null,
  current_Language: "en",
  goflclix_Country: [],
};

const slice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    change_Language: (state, action) => {
      state.current_Language = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getHomePageSetting.fulfilled, (state, action) => {
      state.homePageSetting = _.sortBy(
        _.filter(action?.payload?.data?.data, {
          attributes: { isVisible: true },
        }),
        ["id"]
      );
    });
    builder.addCase(getSetting.fulfilled, (state, action) => {
      state.setting = action?.payload?.data;
    });
    builder.addCase(getPrivacyData.fulfilled, (state, action) => {
      state.privacyData = action?.payload?.data;
    });
    builder.addCase(getCancellationPolicy.fulfilled, (state, action) => {
      state.cancellPolicy = action?.payload?.data;
    });
    builder.addCase(getAbout_US.fulfilled, (state, action) => {
      state.about_Us = action?.payload?.data;
    });
    builder.addCase(getTerms.fulfilled, (state, action) => {
      state.termsData = action?.payload?.data;
    });
    builder.addCase(raiseQuery.fulfilled, (state, action) => {});
    builder.addCase(getGolfclixCountry.fulfilled, (state, action) => {
      if (action?.payload?.status === 200 && action?.payload?.data?.data) {
        state.goflclix_Country = action?.payload?.data?.data;
      }
    });
  },
});

export const { change_Language } = slice.actions;

export default slice.reducer;
